'use client';
import Link from 'next/link';
import NavBar from '@/components/layout/navigation/NavBar';
import MobileNav from '@/components/layout/navigation/MobileNav';
import { useEffect, useState } from 'react';
import type { NavigationSchema } from '@/sanity/schemas/documents/navigation';
import HamburgerIcon from '@/components/elements/icons/HamburgerIcon';
import FullscreenNav from '@/components/layout/navigation/FullscreenNav';
import { usePathname } from 'next/navigation';
import { SiteSettingsSchema } from '@/sanity/schemas/documents/siteSettings';

type Props = {
  siteSettings?: SiteSettingsSchema;
  desktopNav?: NavigationSchema;
  mobileNav?: NavigationSchema;
};

function Header({ siteSettings, desktopNav, mobileNav }: Props) {
  const [desktopOpen, setDesktopOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState(false);
  const pathname = usePathname();

  useEffect(() => {
    if (desktopOpen) {
      setDesktopOpen(false);
    }
  }, [pathname, desktopOpen, open]);

  useEffect(() => {
    setOpen(false);
  }, [pathname]);

  useEffect(() => {
    const handleScroll = () => {
      //@ts-ignore
      setScroll(window.scrollY > 40);
    };
    //@ts-ignore
    window.addEventListener('scroll', handleScroll);
    return () => {
      //@ts-ignore
      window.removeEventListener('click', handleScroll);
    };
  }, []);

  if (!desktopNav && !mobileNav) {
    return null;
  }

  return (
    <header
      className={`top-0 my-4 left-0 right-0 px-10 py-2 lg:py-5 z-50 transition text-black`}
    >
      <div className="mx-auto">
        <div className={`flex flex-row`}>
          <div className={'mr-auto'}>
            <Link
              className={
                'text-brand-400 font-bold text-2xl m-2 hover:underline'
              }
              href="/"
              title={`${siteSettings.title} Home Page`}
            >
              Beautiful Secret
            </Link>
          </div>
          <div
            className={`transition-opacity delay-200 ${scroll ? 'opacity-0 invisible' : 'opacity-100'}`}
          >
            <NavBar desktopNav={desktopNav} />
          </div>
        </div>
        <div className="ml-auto">
          <span
            className={`hidden group lg:flex cursor-pointer transition transition-1000 absolute right-10 top-7 delay-200 ${scroll ? 'opacity-1 delay-200' : 'opacity-0 invisible'}`}
            onClick={() => setDesktopOpen(!desktopOpen)}
          >
            <span
              className={
                'text-3xs tracking-2px font-headings uppercase font-bold inline-block mr-5'
              }
            >
              Menu
            </span>
            <HamburgerIcon
              className={'transition origin-center group-hover:rotate-90'}
            />
          </span>
        </div>
        <FullscreenNav
          desktopNav={desktopNav}
          isOpen={desktopOpen}
          setOpen={setDesktopOpen}
        />
      </div>
      <MobileNav setOpen={setOpen} isOpen={open} mobileNav={mobileNav} />
    </header>
  );
}

export default Header;
