'use client';
import MobileNavLink from '@/components/layout/navigation/MobileNavLink';
import MenuXIcon from '@/components/elements/icons/MenuXIcon';
import type { NavigationSchema } from '@/sanity/schemas/documents/navigation';
import type { NavigationSectionSchema } from '@/sanity/schemas/documents/navigationSection';

type Props = {
  desktopNav: NavigationSchema;
  isOpen: boolean;
  setOpen: (value: boolean) => void;
};

function FullscreenNav({ desktopNav, isOpen, setOpen }: Props) {
  return (
    <>
      {isOpen && (
        <>
          <nav
            className={` bg-black fixed top-0 pt-24 bottom-0 left-0 right-0 overflow-y-auto`}
          >
            <span
              className={'inline-block absolute right-10 top-7'}
              onClick={() => setOpen(!isOpen)}
            >
              <MenuXIcon />
            </span>
            <div className="container mx-auto space-y-8 text-7xl leading-tight flex flex-col px-8 py-12">
              {desktopNav?.sections &&
                desktopNav.sections.map((section: NavigationSectionSchema) => {
                    return (
                      <MobileNavLink key={section._id} href={section.target}>
                        {section.title ?? ''}
                      </MobileNavLink>
                    );
                  }
                )
              }
            </div>
          </nav>
        </>
      )}
    </>
  );
}

export default FullscreenNav;
