import CustomLink from '@/components/functional/CustomLink';

interface Props {
  href: any;
  children: any;
  passHref?: any;
}

function NavLink({ href, children, ...props }: Props) {
  if (href?.internal || href?.external) {
    return (
      <div className="relative group inline-block transition duration-300">
        <CustomLink
          href={href}
          {...props}
          linkClasses={
            'tracking-2px font-headings uppercase block text-heading font-bold'
          }
        >
          {children}
        </CustomLink>
        <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-white" />
      </div>
    );
  }
  return (
    <a
      className={
        'text-3xs tracking-2px font-headings uppercase block text-heading decoration-white decoration-4 hover:underline underline-offset-8 font-bold'
      }
      href={'#'}
    >
      {children}
    </a>
  );
}

export default NavLink;
