import Link from 'next/link';

interface CustomLinkSchema {
  href: any;
  children: any;
  passHref?: any;
  linkClasses?: string;
}

function CustomLink({
  href,
  children,
  linkClasses = 'uppercase text-black hover:underline inline',
  ...props
}: CustomLinkSchema) {
  // internal document link
  if (href?.internal) {
    // cater for home page type
    if (!href.internal?.slug) {
      href.internal.slug = { _type: 'slug', current: '' };
    }
    let content =
      children !== null && children !== ''
        ? children
        : href.internal?.title ?? '';

    return (
      <Link
        className={linkClasses}
        href={'/' + href.internal?.slug?.current ?? '#'}
        target={href.targetBlank ? '_blank' : '_self'}
        {...props}
      >
        {content}
      </Link>
    );
  }

  // offsite link
  if (href?.external) {
    let content = children !== '' ? children : href.external ?? '';
    return (
      <a
        className={linkClasses}
        href={href.external}
        target={href.targetBlank ? '_blank' : '_self'}
      >
        {content}
      </a>
    );
  }

  return null;
}

export default CustomLink;
