import NavLink from '@/components/layout/navigation/NavLink';
import type { NavigationSchema } from '@/sanity/schemas/documents/navigation';
import SubNav from '@/components/layout/navigation/SubNav';

type Props = {
  desktopNav: NavigationSchema;
};

function Navbar({ desktopNav }: Props) {
  return (
    <nav className={'hidden lg:block'}>
      <div className="flex space-x-10 xl:space-x-16 m-2">
        {desktopNav?.sections &&
          desktopNav.sections.map((section) => {
            if (section?.sections?.length) {
              return <SubNav key={section._key} section={section} />;
            }
            return (
              <NavLink key={section._key} href={section.target}>
                {section.title ?? ''}
              </NavLink>
            );
          })}
      </div>
    </nav>
  );
}

export default Navbar;
