import CustomLink from '@/components/functional/CustomLink';

interface CustomLinkSchema {
  href: any;
  children: any;
  passHref?: any;
}

function MobileNavLink({ href, children, ...props }: CustomLinkSchema) {
  if (href?.internal || href?.external) {
    return (
      <CustomLink
        href={href}
        {...props}
        linkClasses={
          'text-white !inline-block hover:text-brandTeal hover:translate-x-2 transition-transform duration-500'
        }
      >
        {children}
      </CustomLink>
    );
  }

  return <span className={'text-white inline'}>{children}</span>;
}

export default MobileNavLink;
