'use client';
import MobileNavLink from '@/components/layout/navigation/MobileNavLink';
import HamburgerIcon from '@/components/elements/icons/HamburgerIcon';
import MenuXIcon from '@/components/elements/icons/MenuXIcon';
import type { NavigationSchema } from '@/sanity/schemas/documents/navigation';
import type { NavigationSectionSchema } from '@/sanity/schemas/documents/navigationSection';

type Props = {
  mobileNav: NavigationSchema;
  isOpen: boolean;
  setOpen: (value: boolean) => void;
};

function MobileNav({ mobileNav, isOpen, setOpen }: Props) {
  return (
    <div className="lg:hidden text-black absolute right-6 top-4 z-50">
      <span
        className={'fixed z-50 right-10'}
        onClick={() => setOpen(!isOpen)}
        id="primary-menu-toggle"
      >
        {!isOpen ? <HamburgerIcon /> : <MenuXIcon />}
      </span>
      {isOpen && (
        <nav
          className={`bg-brand-400/90 fixed top-0 pt-24 bottom-0 left-0 right-0 overflow-y-auto`}
        >
          <div className="container mx-auto space-y-4 text-xl flex flex-col px-8 py-12">
            {mobileNav?.sections &&
              mobileNav.sections.map((section: NavigationSectionSchema) => {
                return (
                  <MobileNavLink key={section._key} href={section.target}>
                    {section.title ?? ''}
                  </MobileNavLink>
                );
              })}
          </div>
        </nav>
      )}
    </div>
  );
}

export default MobileNav;
