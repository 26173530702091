import NavLink from './NavLink';
import type { NavigationSectionSchema } from '@/sanity/schemas/documents/navigationSection';

type Props = {
  section: NavigationSectionSchema;
};

function SubNav({ section }: Props) {
  return (
    <div className="relative group">
      <NavLink href={section.target}>{section.title ?? ''}</NavLink>

      <div className="bg-white shadow min-h-48 invisible text-sm group-hover:visible absolute right-0 w-48 rounded-md overflow-hidden z-10">
        {section?.sections &&
          section.sections.map((section: NavigationSectionSchema) => {
            if (section?.sections && section.sections.length) {
              return <SubNav section={section} key={section._id} />;
            }
            return (
              <NavLink key={section._id} href={section.target}>
                {section.title}
              </NavLink>
            );
          })}
      </div>
    </div>
  );
}

export default SubNav;
