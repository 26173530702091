function MenuXIcon() {
  return (
    <>
      <svg
        className={
          'block absolute top-0 right-0 hover:rotate-90 transition cursor-pointer z-50'
        }
        xmlns="http://www.w3.org/2000/svg"
        width="45"
        height="45"
        viewBox="0 0 45 45"
        fill="none"
      >
        <path
          d="M21.0316 22.5075L9.31467 10.784C8.89511 10.3643 8.89511 9.73463 9.31467 9.31484C9.73423 8.89505 10.3635 8.89505 10.7831 9.31484L22.5 21.0383L34.2169 9.31484C34.6066 8.92501 35.2658 8.92501 35.6853 9.31484C36.1049 9.73463 36.1049 10.3643 35.6853 10.784L23.9684 22.5075L35.6853 34.231C36.1049 34.6508 36.1049 35.2804 35.6853 35.7002C35.4756 35.91 35.2059 36 34.9661 36C34.7263 36 34.4267 35.91 34.2469 35.7002L22.5 23.9767L10.783 35.7002C10.5733 35.91 10.3036 36 10.0638 36C9.82403 36 9.52436 35.91 9.34458 35.7002C8.92503 35.2804 8.92503 34.6508 9.34458 34.231L21.0316 22.5075Z"
          fill="white"
        />
      </svg>
    </>
  );
}

export default MenuXIcon;
