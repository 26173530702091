import React from 'react';

const HamburgerIcon = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="46"
      height="45"
      viewBox="0 0 46 45"
      fill="none"
    >
      <path
        d="M41.4494 22.5001C41.4494 22.7387 41.3549 22.9677 41.1865 23.1364C41.0182 23.3053 40.7898 23.4001 40.5518 23.4001H4.64757C4.32689 23.4001 4.03061 23.2286 3.8702 22.9501C3.70993 22.6716 3.70993 22.3286 3.8702 22.0501C4.03061 21.7716 4.3269 21.6001 4.64757 21.6001H40.5518C40.7898 21.6001 41.0182 21.6949 41.1865 21.8638C41.3549 22.0325 41.4494 22.2614 41.4494 22.5001ZM4.64757 14.4001H40.5518C40.8725 14.4001 41.1688 14.2286 41.3292 13.9501C41.4895 13.6716 41.4895 13.3286 41.3292 13.0501C41.1688 12.7716 40.8725 12.6001 40.5518 12.6001H4.64757C4.3269 12.6001 4.03062 12.7716 3.87021 13.0501C3.70994 13.3286 3.70994 13.6716 3.87021 13.9501C4.03062 14.2286 4.3269 14.4001 4.64757 14.4001ZM40.5518 30.6001H4.64757C4.3269 30.6001 4.03062 30.7716 3.87021 31.0501C3.70994 31.3286 3.70994 31.6716 3.87021 31.9501C4.03062 32.2286 4.3269 32.4001 4.64757 32.4001H40.5518C40.8725 32.4001 41.1688 32.2286 41.3292 31.9501C41.4895 31.6716 41.4895 31.3286 41.3292 31.0501C41.1688 30.7716 40.8725 30.6001 40.5518 30.6001Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default HamburgerIcon;
